import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { useAuth } from '../modules/auth'

import {Private} from '../modules/apps/chat/components/Private'

const PrivateRoutes = () => {
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  const {currentUser} = useAuth()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        {currentUser !== undefined && currentUser.role === 'admin' ? (
          <>
            <Route path='dashboard/*' element={<Navigate to='/apps/user-management/users' />} />
            <Route path='auth/*' element={<Navigate to='/apps/user-management/users' />} />
            
          </>
          
        ) : (
            <>
               <Route path='auth/*' element={<Navigate to='/chat' />} />
              <Route path='chat/' element={
            <>
              <Private />
            </>
          }/>
            </>
        )}
        
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
