/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import { ChatInner } from '../../../../../_metronic/partials'


const Private: FC = () => {
  
  const [newflag, setNewChatflag] = useState<boolean>(false)
   const onNewChat = () => {
     setNewChatflag(!newflag)
  }

  return (
    <div className='d-flex flex-column flex-lg-row mt-10'>
      <div className='flex-lg-row-fluid'>
        <div className='card' id='kt_chat_messenger'>
          <div className='card-header' id='kt_chat_messenger_header'>
            <div className='card-title'>
              <div className='symbol-group symbol-hover'></div>
              <div className='d-flex justify-content-center flex-column me-3'>
                <a
                  href='#'
                  className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'
                >
                  ChatGPT
                </a>
              </div>
            </div>

            <div className='card-toolbar'>
              <div className='me-n3'>
                <button
                  className='btn btn-sm btn-primary'
                  onClick={onNewChat}>
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                  New Chat
                </button>
              </div>
            </div>
          </div>
          <ChatInner initialflag={ newflag } />
        </div>
      </div>
    </div>
  )
}

export {Private}
