/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { currentUser } = useAuth()
  return (
    <>
      {currentUser !== undefined && currentUser.role === 'admin'  ? (
            <>
              <SidebarMenuItem
                to='/apps/user-management/users'
                icon='/media/icons/duotune/general/gen051.svg'
                title='User management'
                fontIcon='bi-layers'
              />
               <SidebarMenuItem
                to='/apps/transaction/history'
                icon='/media/icons/duotune/abstract/abs027.svg'
                title='Transaction History'
                fontIcon='bi-layers'
              />
            </>
      ) : (
        <>

      <SidebarMenuItem
        to='/chat'
        icon='/media/icons/duotune/communication/com012.svg'
        title='ChatGPT'
        fontIcon='bi-app-indicator'
      />
          </>
        )
      }
      
    </>
  )
}

export {SidebarMenuMain}
