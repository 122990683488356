import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const CHATBOT_URL = `${API_URL}/chatbot`

export function getMessageFromChatBot(
   message: string
) {
  return axios.post(CHATBOT_URL, {
    "message": message
  })
}
