import axios from 'axios'
import { AuthModel, UserModel } from './_models'
import * as authHelper from './AuthHelpers'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const USER_LOGOUT_URL = `${API_URL}/user_logout`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

export const GET_BALANCE_BY_ACCESSTOKEN_URL = `${API_URL}/user_balance`


// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    "token": token,
  })
}

// export function sendUserLogOut() {
//   return axios.get<UserModel>(USER_LOGOUT_URL)
// }
export function sendUserLogOut() {
  const auth = authHelper.getAuth()
  if (auth !== undefined) {
     return axios.post(USER_LOGOUT_URL, {
      "token": auth.api_token
    })
  }
 
}