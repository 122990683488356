/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect, useRef} from 'react'
import clsx from 'clsx'
import {
  toAbsoluteUrl,
  defaultMessages,
  MessageModel,
  messageFromClient,
} from '../../helpers'

import { getMessageFromChatBot } from '../../../app/modules/api/_requests'

type Props = {
  isDrawer?: boolean
  initialflag?: boolean
}

let bufferMessages : Array<MessageModel> = []
const ChatInner: FC<Props> = ({isDrawer = false, initialflag = false}) => {
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState<MessageModel[]>(bufferMessages)
  const [loading, setLoading] = useState(false);
  

  const messagesEndRef = useRef<null | HTMLDivElement>(null); 

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const scrollSize = windowSize.current[1] - 385;
  console.log(windowSize.current[0], windowSize.current[1])
  // useEffect(() => {
  //      console.log("master")
  //     // scrollToBottom()
  //   }, [messages]);
  useEffect(() => {
      bufferMessages = [];
      setMessages(bufferMessages)
      // scrollToBottom()
    }, [initialflag]);

  const sendMessage = async () => {
    setLoading(true)
    const newMessage: MessageModel = {
      user: 2,
      type: 'out',
      text: message,
      time: 'Just now',
    }
    bufferMessages.push(newMessage)
    setMessages(bufferMessages)
    scrollToBottom()
    toggleChatUpdateFlat(!chatUpdateFlag)
    setMessage('')
    try {
          const {data: msg_content} = await getMessageFromChatBot(
            message,
          )
          const newMessage: MessageModel = {
            user: 2,
            type: 'in',
            text: msg_content,
            time: 'Just now',
          }
          bufferMessages.push(newMessage)
          setMessages(() => bufferMessages)
          toggleChatUpdateFlat((flag) => !flag)
          setLoading(false)
        } catch (error) {
          console.error(error)
          setLoading(false)
        }
    
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  return (
    <div
      className='card-body'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      <div
        className={clsx('scroll-y me-n5 pe-5 pt-5', {'h-300px h-lg-auto': !isDrawer})}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
        style={{maxHeight:"40px", minHeight:`${scrollSize}px`}}
      >
        {messages.map((message, index) => {
          const state = message.type === 'in' ? 'info' : 'primary'
          const templateAttr = {}
          if (message.template) {
            Object.defineProperty(templateAttr, 'data-kt-element', {
              value: `template-${message.type}`,
            })
          }
          const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
            message.type === 'in' ? 'start' : 'end'
          }`
          return (
            <div
              key={`message${index}`}
              className={clsx('d-flex', contentClass, {'d-none': message.template})}
              {...templateAttr}
            >
              <div
                className={clsx(
                  'd-flex flex-column align-items',
                  `align-items-${message.type === 'in' ? 'start' : 'end'}`
                )}
              >
                <div className='d-flex align-items-center mb-2'>
                  {message.type === 'in' ? (
                    <>
                      <div className='symbol  symbol-35px symbol-circle '>
                        <img alt='Pic' src={toAbsoluteUrl(`/media/avatars/chatbot.jpg`)} />
                      </div>
                      <div className='ms-3'>
                        <div
                          className={clsx(
                            'p-5 rounded',
                            `bg-light-${state}`,
                            'text-dark fw-bold mw-lg-800px',
                            `text-start'}`
                          )}
                          data-kt-element='message-text'
                          dangerouslySetInnerHTML={{__html: message.text}}
                        ></div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='me-3'>
                        <div
                          className={clsx(
                            'p-5 rounded',
                            `bg-light-${state}`,
                            'text-dark fw-bold mw-lg-800px',
                            `text-end'}`
                          )}
                          data-kt-element='message-text'
                          dangerouslySetInnerHTML={{__html: message.text}}
                        ></div>
                      </div>
                      <div className='symbol  symbol-35px symbol-circle '>
                        <img alt='Pic' src={toAbsoluteUrl(`/media/avatars/you.jpg`)} />
                      </div>
                    </>
                  )}
                </div>

                
              </div>
            </div>
          )
        })}
        {loading && (
            <div className={clsx('d-flex', 'justify-content-start')}>
            <div
              className={clsx(
              'd-flex flex-column align-items',
              `align-items-start`
              )}
            >
              <div className='d-flex align-items-center mb-2'>
                  <div className='symbol  symbol-35px symbol-circle '>
                      <img alt='Pic' src={toAbsoluteUrl(`/media/avatars/chatbot.jpg`)} />
                  </div>
                  <div className='ms-3'>
                      <div
                          className={clsx(
                            'p-5 rounded',
                            `bg-light-info`,
                            'text-dark fw-bold mw-lg-800px',
                            `text-start'}`
                          )}
                          data-kt-element='message-text'
                      >
                        <div className="spinner-grow spinner-grow-sm me-2" role="status"></div>
                        <div className="spinner-grow spinner-grow-sm me-2" role="status"></div>
                        <div className="spinner-grow spinner-grow-sm" role="status"></div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        )}
        <div className="h-200px" id='bottom' ref={messagesEndRef}/>
      </div>
      
      <div
        className='card-footer'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <textarea
          className='form-control form-control-flush mb-3'
          rows={1}
          data-kt-element='input'
          placeholder='Type a message'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
        ></textarea>

        {/* <div className='d-flex flex-stack'>
          <div className='d-flex align-items-center me-2'>
          </div>
          <button
            className='btn btn-primary'
            type='button'
            data-kt-element='send'
            onClick={sendMessage}
          >
            Send
          </button>
        </div> */}
      </div>
    </div>
  )
}

export {ChatInner}
